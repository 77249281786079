import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetOneApi } from "rk-react-component";
import { FormTask } from "../../../containers/forms/form-task/form-task";
import { PageFormTabContainer } from "../../../containers/pages/page-form-tab-container/page-form-tab-container";
import { api } from "../../../services/api";

export const TaskFormPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { data, loading } = useGetOneApi<any>({ url: "/tasks", id, api});
    const [activeKey, setActiveKey] = React.useState("tab_task");
    return (
        <PageFormTabContainer
            activeKey={activeKey}
            setActiveKey={setActiveKey}
            title={"Atividades"}
            items={[
                {
                    disabled: false,
                    key: "tab_task",
                    label: "Info Geral",
                    loading,
                    title: "Novo Usuário",
                    FormComponent: (
                        <FormTask
                            initialValues={data}
                            onBack={() => navigate('/tasks')}
                            onFinish={(dataApi) => {
                                navigate(`/tasks/${dataApi?.id}`);
                            }}
                        />
                    ),
                },
            ]}
        />
    );
};
