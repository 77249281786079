import {
    Link,
    useLocation,
} from "react-router-dom";
import React, { useContext } from "react";
import { Layout } from "rk-react-component";
import { configProject } from "../config-project";
import { FooterLayout } from "./footer-layout";
import { MenuExtraRender } from "./menu-extra-render";
import { useRouteRole } from "../hooks/use-route-role";
import { Spin } from "antd";
import { AuthRoutes } from "./auth-routes";
import { useAuth } from "../hooks/use-auth";
import { MenuHeaderRender } from "./menu-header-render";
import { MeContext } from "../contexts/me-context/me-context";

export const MainLayout = ({ me, role }) => {
    const location = useLocation();
    const {collapsed, setCollapsed} = useContext(MeContext);
    const { routes } = useRouteRole();
    const { loading, style, setLoading } = useAuth({ me });

    if (!me?.id) {
        return (<AuthRoutes />);
    }

    return (
        <Spin spinning={loading}>
            <Layout
                breakpoint={false}
                menuHeaderRender={(logo, _title, configuration) => <MenuHeaderRender configuration={configuration} logo={logo}/>}
                menuExtraRender={({ collapsed, isMobile }) => (
                    <MenuExtraRender
                        collapsed={collapsed}
                        isMobile={isMobile}
                        setLoading={setLoading}
                    />
                )}
                token={configToken}
                collapsed={collapsed}
                onCollapse={(collapsed) => setCollapsed(collapsed)}
                locationRouter={location}
                logo={require("../assets/icon-bordered.png")}
                title={"RockApps"}
                LinkComponent={Link}
                routes={routes.location}
                siderMenuType={"group"}
                menuFooterRender={FooterLayout}
                style={{ height: "100vh" }}
            >
                <div style={style}>{routes.routes}</div>
            </Layout>
        </Spin>
    );
};

const configToken = {
    sider: {
        colorMenuBackground: configProject.colorMenuBackground,
        colorTextMenu: configProject.colorTextMenu,
        colorTextMenuSelected:
        configProject.colorTextMenuSelected,
        colorTextMenuItemHover:
        configProject.colorTextMenuSelected,

        colorTextMenuTitle: configProject.colorMenuBackground,
        colorBgCollapsedButton:
        configProject.colorMenuBackground,
        colorBgMenuItemCollapsedElevated:
        configProject.colorMenuBackground
    },
    colorPrimary: configProject.colorPrimary,
    header: { colorBgHeader: configProject.colorBgHeader }
};
