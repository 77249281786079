import { Avatar, Tooltip, Typography } from "antd";
import React from "react";
import { TableContainer, TableContainerProps } from "rk-react-component";
import { PlayTimerAction } from "../../../components/play-timer/play-timer";
import { api } from "../../../services/api";
import moment from "moment";
import { ModelEnum } from "../../../@types/model.enum";
import { TaskTagAction } from "../../../components/task-tag-action/task-tag-action";
import { SelectAssigneesAction } from "../../inputs/select-assignees-action/select-assignees-action";
import './style.css'
interface TableTaskProps extends TableContainerProps<any> {
    updateDataList?: any
}

export const TableTask = ({
                              dataSource,
                              loading,
                              loadData,
                              pagination,
                              onEdit,
                              filter,
                              onDelete,
                              onView,
                              updateDataList
                          }: TableTaskProps) => {
    return (
        <TableContainer
            onView={onView}
            filter={filter}
            onEdit={onEdit}
            onDelete={onDelete}
            pagination={pagination}
            loadData={loadData}
            id={"table_task"}
            loading={loading}
            actionClickRow={"onView"}
            columns={[
                {
                    key: "title",
                    dataIndex: "title",
                    title: "Título",
                    sorter: true,
                    render: (val) => (
                        <div style={{ minWidth: 280 }}>
                            <Typography.Text strong>{val}</Typography.Text>
                        </div>
                    )
                },
                {
                    key: "users_assignees",
                    dataIndex: "users_assignees",
                    title: "Atribuído para",
                    // render: (val) => (
                    //         <Avatar.Group>
                    //             {val?.map((user) => (
                    //                 <Tooltip title={user.name}>
                    //                     <Avatar
                    //                         key={user.id}
                    //                         src={user?.avatar}
                    //                     >
                    //                         {user?.name[0]}
                    //                     </Avatar>
                    //                 </Tooltip>
                    //             ))}
                    //         </Avatar.Group>
                    // )
                    render: (val, record) => (
                        <SelectAssigneesAction
                            style={{}}
                            model={record}
                            values={val}
                            onChange={() => {}}
                            updateDataList={updateDataList}
                        />
                    )
                },
                {
                    key: "status",
                    dataIndex: "status",
                    title: "Status",
                    render: (val, record) => <TaskTagAction
                        task={record}
                        val={val}
                        updateDataList={updateDataList}
                        fieldName={"status_id"}
                        labelName={"name"}
                    />
                },
                {
                    key: "priority",
                    dataIndex: "priority",
                    title: "Prioridade",
                    render: (val, record) => <TaskTagAction
                        task={record}
                        val={val}
                        updateDataList={updateDataList}
                        fieldName={"priority_id"}
                        labelName={"name"}
                    />
                },
                {
                    key: "task_timer",
                    dataIndex: "task_timer",
                    title: "Tempo",
                    render: (val, record) => <PlayTimerAction
                        api={api}
                        url={"task-timers"}
                        modelId={record.id} timer={record?.openTimer}
                        totalTimer={record?.totalTimer}
                        onPlay={() => {
                            api.post("/timers", {
                                model_type: ModelEnum.TASK,
                                model_id: record.id,
                                start_at: moment().toISOString()
                            }).then(() => {
                                loadData({});
                            });
                        }}
                        onPause={() => {
                            api.put("/timers/" + record?.openTimer?.id, {
                                end_at: moment().toISOString()
                            }).then(() => {
                                loadData({});
                            });
                        }}
                    />
                },
                {
                    key: "project",
                    dataIndex: "project",
                    title: "Projeto",
                    sorter: true,
                    render: (val) => (
                        <div style={{ minWidth: 280 }}>
                            <Typography.Text strong>{val?.name || "-"}</Typography.Text>
                        </div>
                    )
                }
            ]}
            dataSource={dataSource}
        />
    );
};
