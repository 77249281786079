import React, { useState } from "react";
import { LoginForm } from "@ant-design/pro-components";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { SigninFastLogin } from "./signin-fast-login";
import { Divider, Form, Image, Row, Tabs } from "antd";
import { Link } from "react-router-dom";
import { FormSignInEmail } from "../../containers/forms/form-auth/form-sign-in-email";
import { FormSignInPhone } from "../../containers/forms/form-auth/form-sign-in-phone";
import { useCountDown } from "ahooks";

export const SignInPage = ({ onFinish, loading }) => {
  const [form] = Form.useForm();
  const [targetDate, setTargetDate] = useState<number>();
  const [_countdown, formattedRes] = useCountDown({
    targetDate: targetDate,
    onEnd: () => {
      setTargetDate(undefined);
    },
  });
  const actionSignin = (data) => {
    setTargetDate(undefined);
      onFinish(data);
  };
  return (
    <div
      style={{
        backgroundColor: "white",
        height: "calc(100vh)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <div style={{ display: "flex", flex: 1 }} />
      <div style={{ display: "flex", flex: 1 }}>
        <LoginForm
          loading={loading}
          style={{ marginTop: "auto" }}
          form={form}
          onFinish={async (data: any): Promise<void> => {
            actionSignin(data);
          }}
        >
          <Row justify={"center"}>
            <Image
              src={require("../../assets/logo-color-vertical.png")}
              preview={false}
              style={{ maxWidth: 240 }}
            />
            <Divider />
          </Row>
            <FormSignInEmail />

          <div
            style={{
              marginBottom: 16,
              marginTop: -16,
              justifyContent: "flex-end",
              display: "flex",
            }}
          >
            <Link to={"/forgot-password"}>Esqueci minha senha</Link>
          </div>
          {process.env.REACT_APP_SHOW_HELPER === "on" ? (
            <SigninFastLogin />
          ) : null}
        </LoginForm>
      </div>
      <div style={{ display: "flex", flex: 2 }} />
    </div>
  );
};
