import moment from "moment/moment";
import { Moment } from "moment";

export const formatSecondToTimer = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

export const formatDate = (
  date: string | Date | Moment,
  format = "DD/MM/YYYY HH:mm",
  relativeDate = false,
  relativeSuffix = false,
) => {
  if (!date) return null;

  const dateAux = moment(date);

  if (relativeDate) {
    return dateAux.fromNow(relativeSuffix);
  }
  return dateAux.format(format);
};

export const extractNameInitials = (
  fullName: string,
  firstAndLast = true,
): string => {
  if (!fullName) return "";

  const words = fullName?.trim().split(" ");

  const initials = [];

  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    if (word.length > 0) {
      initials.push(word?.[0]?.toUpperCase());
    }
  }

  if (firstAndLast && words.length > 1) {
    return initials?.[0] + initials?.[initials?.length - 1];
  }

  return initials.join("");
};

export const extractNamesFromUser = (fullName?: string): string => {
  if (!fullName) return "";

  const words = fullName.split(" ");

  if (words.length > 1) {
    return words[0] + " " + words[words.length - 1];
  }

  return words.join("");
};

export const formatBrazilianPhoneNumber = (
  phoneNumber: string,
  defaultText = "",
) => {
  if (!phoneNumber) return defaultText;
  // Remove any non-digit characters from the input
  const cleanedPhoneNumber = phoneNumber.replace(/\D/g, "");

  // Check if the cleaned phone number has the correct length (10 or 11 digits)
  if (cleanedPhoneNumber.length === 10) {
    // Format as landline: (XX) XXXX-XXXX
    return `(${cleanedPhoneNumber.slice(0, 2)}) ${cleanedPhoneNumber.slice(
      2,
      6,
    )}-${cleanedPhoneNumber.slice(6, 10)}`;
  } else if (cleanedPhoneNumber.length === 11) {
    // Format as mobile: (XX) XXXXX-XXXX
    return `(${cleanedPhoneNumber.slice(0, 2)}) ${cleanedPhoneNumber.slice(
      2,
      7,
    )}-${cleanedPhoneNumber.slice(7, 11)}`;
  } else {
    // If the phone number doesn't have the expected length, return it as is
    return phoneNumber;
  }
};

export const formatBrazilianZipCode = (zipCode: string) => {
  // Remove any non-digit characters from the input string
  const digitsOnly = zipCode.replace(/\D/g, "");

  // Apply the desired format: XXXXX-XXX
  return digitsOnly.replace(/^(\d{5})(\d{3})$/, "$1-$2");
};

export const replaceEndWithX = (str: string, quantity: number): string => {
  const endIndex = str.length - quantity;
  const replacedEnd = "X".repeat(quantity);
  const replacedStr = str.substr(0, endIndex) + replacedEnd;

  return replacedStr;
};
