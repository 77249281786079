import { Avatar, Flex, Space, Typography } from "antd";
import React from "react";

interface UserSelectRenderProps {
    user: any;
    size?: number;
    hiddenEmail?: boolean;
}
export const UserSelectRender = ({user, size = 24, hiddenEmail}: UserSelectRenderProps) => {
  const avatar = user?.avatar;
  return (
    <Flex align={"center"} gap={8} flex={1} style={{height: '100%'}}>
        <Flex>
            <Avatar size={size} src={avatar}>{user?.name[0]}</Avatar>
        </Flex>
      <Flex flex={1} justify={"center"} vertical={true}>
        <Typography.Text strong style={{fontSize: 12}}>{user?.name}</Typography.Text>
          {!hiddenEmail && <Typography.Text style={{fontSize: 10}}>{user?.email}</Typography.Text>}
      </Flex>
    </Flex>
  );
};
