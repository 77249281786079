import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "./useWindowDimensions";

interface UseAuthProps {
    me: any;
}

export const useAuth = ({ me }: UseAuthProps) => {
    const [isMounted, setIsMounted] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { width } = useWindowDimensions();
    const style =
        width < 500
            ? { marginLeft: -16, marginRight: -16, marginBottom: 100 }
            : {};
    useEffect(() => {
        setIsMounted(true);
        if (isMounted) {
            if (
                !me?.id &&
                location.pathname !== "/signin" &&
                location.pathname !== "/reset-password"
            ) {
                navigate("/signin");
            }else if (me?.id && location.pathname === "/signin") {
                navigate("/");
            }
        }
    }, [me, isMounted]);

    return { loading, width, style, setLoading };
};