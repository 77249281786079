import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { LoginForm, ProFormText } from "@ant-design/pro-components";
import { LockOutlined } from "@ant-design/icons";

export const ResetPassword = ({ reload }) => {
  const location = useLocation();
  const [_token, setToken] = useState("");
  useEffect(() => {
    if (reload) {
      localStorage.clear();
      window.location.reload();
    } else {
      const params = Object.fromEntries(new URLSearchParams(location.search));
      setToken(params?.token);
    }
  }, []);

  return (
    <div
      style={{
        backgroundColor: "white",
        height: "calc(100vh)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <div style={{ display: "flex", flex: 1 }} />
      <div style={{ display: "flex", flex: 1 }}>
        <LoginForm
          loading={false}
          style={{ marginTop: "auto" }}
          onFinish={undefined}
          logo={require("../../assets/logo-color-vertical.png")}
        >
          <ProFormText.Password
            name="password"
            fieldProps={{
              size: "large",
              prefix: <LockOutlined className={"prefixIcon"} />,
            }}
            placeholder={"Senha"}
          />
          <ProFormText.Password
            name="password_confirmation"
            fieldProps={{
              size: "large",
              prefix: <LockOutlined className={"prefixIcon"} />,
            }}
            placeholder={"Confirmação de Senha"}
          />
        </LoginForm>
      </div>
      <div style={{ display: "flex", flex: 2 }} />
    </div>
  );
};
