// MODULES
import { MdDashboard } from "react-icons/md";
import { FiFilm } from "react-icons/fi";
import { FaEdit, FaEye, FaPlus, FaTasks, FaTrash, FaUsers } from "react-icons/fa";
import { RiAdminLine } from "react-icons/ri";
import { TbReportAnalytics } from "react-icons/tb";
import { BiNotification } from "react-icons/bi";
import { GrProjects } from "react-icons/gr";

export const DashboardIcon = MdDashboard;
export const ReportIcon = FiFilm;
export const UserIcon = FaUsers;
export const IntegrationLogsIcon = TbReportAnalytics;

export const ProjectIcon = GrProjects;
export const TaskIcon = FaTasks;

//OTHERS
export const EditIcon = FaEdit;
export const GoToIcon = FaEye;
export const DeleteIcon = FaTrash;
export const AddIcon = FaPlus;
export const NotificationIcon = BiNotification;
