import { SelectAction, useGetAllApi } from "rk-react-component";
import { api } from "../../../services/api";
import React, { useMemo } from "react";
import { SelectUser } from "../select-user/select-user";
import { Avatar, Dropdown, Flex, Space, Spin, Tag, Tooltip } from "antd";
import { ConfigOptionTagAction } from "../../../components/config-option-tag-action/config-option-tag-action";
import { UserSelectRender } from "../../../components/user-select-render/user-select-render";
import { FaCaretDown, FaPlus } from "react-icons/fa";
import _ from "lodash";

export const SelectAssigneesAction = ({ values, onChange, updateDataList, style, model, ...props }) => {
    const { dataList, fetchDataList, loading } = useGetAllApi<any, any>({
        url: "/users",
        api: api,
        initialRequest: false
    });
    const items = useMemo(() => {
        return _.uniqBy(_.concat(model?.users_assignees, dataList), 'id').map((i) => ({
            key: i.id,
            label: (<UserSelectRender user={i} />)
        }));
    }, [dataList]);
    return (
        <Dropdown
            trigger={["click"]}
            overlayStyle={{ minHeight: 80, ...style }}
            dropdownRender={(menu) => (
                <Spin style={{ minHeight: 80, backgroundColor: "white" }} spinning={loading}>
                    {menu}
                </Spin>
            )}
            menu={{
                items,
                selectable: true,
                multiple: true,
                selectedKeys: values.map((i) => i.id.toString()),
                defaultSelectedKeys: values.map((i) => i.id.toString()),
                onDeselect: ({item, selectedKeys, ...e}) => {
                    console.log('deselect')
                    api.put(`/tasks/${model.id}`, {
                        id: model.id,
                        users_assignees: selectedKeys
                    }).then(({ data }) => {
                        updateDataList(data.data)
                    })
                    e.domEvent.stopPropagation();

                },
                onSelect: ({item, selectedKeys, ...e}) => {
                    console.log('deselect')
                    api.put(`/tasks/${model.id}`, {
                        id: model.id,
                        users_assignees: selectedKeys
                    }).then(({ data }) => {
                        updateDataList(data.data)
                    })
                    e.domEvent.stopPropagation();

                },
            }}
            onOpenChange={(open) => {
                if (open) {
                    fetchDataList({});
                }
            }}
        >
                <div
                    style={{ cursor: "pointer" }}
                    onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                    }}
                >
                    <Space size={4}>
                        <Avatar.Group>
                            {values?.length ? values?.map((user) => (
                                <Tooltip title={user.name} key={user.id}>
                                    <Avatar
                                        key={user.id}
                                        src={user?.avatar}
                                    >
                                        {user?.name[0]}
                                    </Avatar>
                                </Tooltip>
                            )) : (
                                <Tooltip title={'Adicionar'} key={'add'}>
                                    <Avatar style={{backgroundColor: 'transparent', color: 'gray', border: '1px gray dashed'}}>
                                        +
                                    </Avatar>
                                </Tooltip>
                            )}
                        </Avatar.Group>
                        <FaCaretDown />
                    </Space>
                </div>
        </Dropdown>
    );
};