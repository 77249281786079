import { SelectAction } from "rk-react-component";
import { api } from "../../../services/api";
import React from "react";
import { SelectUser } from "../select-user/select-user";

export const SelectAssignees = ({...props}) => {
    return (
        <SelectUser
            mode={'multiple'}
            {...props}
        />
    )
}