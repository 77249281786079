import {
    Avatar,
    Button,
    Divider,
    Popconfirm,
    Space,
    Tooltip,
    Typography
} from "antd";
import { BiLogOut } from "react-icons/bi";
import { configProject } from "../config-project";
import React, { ReactNode, useContext } from "react";
import { MeContext } from "../contexts/me-context/me-context";
import { useNavigate } from "react-router-dom";
import { translateModelApi } from "../services/translates";
import { FaUser } from "react-icons/fa";
import { NotificationDrawer } from "../components/notification-drawer/notification-drawer";
import { formatAvatarSlug } from "rk-react-component";
import { IMG_DEFAULT } from "../services/constants";

interface MenuExtraRenderProps {
    collapsed: boolean;
    isMobile: boolean;
    setLoading: any;
}

const ICON_SIZE = 16;
export const MenuExtraRender = ({
                                    collapsed,
                                    isMobile,
                                    setLoading
                                }: MenuExtraRenderProps): ReactNode => {
    const navigate = useNavigate();
    const { setMe, setRole, me } = useContext(MeContext);
    const style = isMobile ? { marginLeft: 16 } : {};
    if (collapsed) {
        return (
            <>
                <Space direction={"vertical"} align={"center"} style={{ width: "100%" }}>
                    <Avatar
                        size={"large"}
                        src={me?.avatar || IMG_DEFAULT}
                    >
                        {formatAvatarSlug(me?.name)}
                    </Avatar>
                </Space>
                <Divider style={{ marginBottom: 8, marginTop: 8, backgroundColor: "black", width: "100%" }} />
                <Space direction={"vertical"} align={"center"} style={{ width: "100%" }}>
                    <Tooltip title={"Meu Perfil"}>
                        <Button
                            type={"link"}
                            icon={<FaUser size={ICON_SIZE} color={configProject.colorTextMenuSelected} />}
                            onClick={() => {
                                navigate(`/my-profile/${me?.id}`);
                            }}
                        />
                    </Tooltip>
                    <NotificationDrawer color={configProject.colorTextMenuSelected} iconSize={ICON_SIZE} />
                </Space>
                <Divider style={{ marginBottom: 8, marginTop: 0, backgroundColor: "black" }} />
            </>
        );
    }
    return (
        !collapsed && (
            <Space direction={"vertical"} style={{ ...style, marginTop: 8 }}>
                <Space direction={"horizontal"}>
                    <Avatar
                        size={"large"}
                        style={{ marginRight: 8 }}
                        src={me?.avatar || IMG_DEFAULT}
                    >
                        {formatAvatarSlug(me?.name)}
                    </Avatar>
                    <div>
                        <Typography.Title level={4} style={{ color: "white", margin: 0 }}>
                            {me?.name}
                        </Typography.Title>
                    </div>
                </Space>
                <Divider style={{ marginBottom: 8, marginTop: 0, backgroundColor: "black" }} />
                <Space direction={"horizontal"}>
                    <Tooltip title={"Meu Perfil"}>
                        <Button
                            type={"link"}
                            icon={<FaUser size={ICON_SIZE} color={configProject.colorTextMenuSelected} />}
                            onClick={() => {
                                navigate(`/my-profile/${me?.id}`);
                            }}
                        />
                    </Tooltip>
                    <NotificationDrawer color={configProject.colorTextMenuSelected} iconSize={ICON_SIZE} />
                    <Tooltip title={"Sair"}>
                        <Popconfirm
                            title={"Tem certeza que deseja sair?"}
                            onConfirm={() => {
                                localStorage.clear();
                                setMe(null);
                                setRole(null);
                                navigate("/signin");
                            }}
                        >
                            <Button
                                type={"link"}
                                icon={
                                    <BiLogOut
                                        size={ICON_SIZE}
                                        style={{ cursor: "pointer" }}
                                        color={configProject.colorTextMenuSelected}
                                    />
                                }
                            />
                        </Popconfirm>
                    </Tooltip>
                </Space>
                <Divider style={{ marginBottom: 8, marginTop: 0, backgroundColor: "black" }} />
            </Space>
        )
    );
};
