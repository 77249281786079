import {Button, Card, Divider} from "antd";
import React, {ReactNode} from "react";
import {useNavigate} from "react-router-dom";
import {PageHeader} from "../../../components/page-header/page-header";

interface PageIndexContainerProps {
  url: string;
  title: string;
  btnTitle?: string;
  FormFilterComponent: any;
  TableComponent: any;
  extra?: ReactNode[];
  showBtnAdd?: boolean;
}

export const PageIndexContainer = ({
  url,
  title,
  btnTitle = 'Novo',
  FormFilterComponent,
  TableComponent,
  showBtnAdd = true,
  extra,
}: PageIndexContainerProps) => {
  const navigate = useNavigate();
  return (
    <>
      <PageHeader
        title={title}
        onBack={() => navigate(-1)}
        extra={
          extra?.length
            ? extra
            : showBtnAdd
            ? [
                <Button
                  data-testid={"btn_new_model"}
                  key={"btn_new_model"}
                  type={"primary"}
                  onClick={() => navigate(`${url}/store`)}
                >
                  {btnTitle}
                </Button>,
              ]
            : []
        }
      />
      <Divider />
        {FormFilterComponent && <Card size={"small"}>{FormFilterComponent}</Card>}
      <br />
      <TableComponent />
    </>
  );
};
