import { project, task, user } from "./routes-modules";
import {Route, Routes} from "react-router-dom";
import React from "react";

export const routesDefault = () => {
    return {
        location: [
            user.location,
            project.location,
            task.location
        ],
        routes: (
            <Routes>
                {user.routes}
                {project.routes}
                {task.routes}
            </Routes>
        ),
    };
};
