import React from "react";
import {
  FormFilterContainer,
  FormFilterItem,
  InputPhone,
} from "rk-react-component";
import { Form, Input } from "antd";

interface FormFilterPartyProps {
  onFinish: (data: any) => void;
  initialValues: any;
}

export const FormFilterUser = ({
  onFinish,
  initialValues,
}: FormFilterPartyProps) => {
  const [form] = Form.useForm();
  console.log(initialValues);
  return (
    <FormFilterContainer
      formProps={{
        initialValues: initialValues,
        onFinish: (data) => {
          onFinish({ ...initialValues, ...data});
        },
      }}
      form={form}
      onReset={() => onFinish({})}
      hiddenShowMore={true}
      showTrashOption={true}
    >
      {() => (
        <>
          <FormFilterItem name={"name_lk"}>
            <Input placeholder={"Nome"} data-testid={"name_lk"} />
          </FormFilterItem>
          <FormFilterItem name={"email_lk"}>
            <Input placeholder={"Email"} data-testid={"email_lk"} />
          </FormFilterItem>
        </>
      )}
    </FormFilterContainer>
  );
};
