import { Card, Divider } from "antd";
import React, { useMemo } from "react";
import { PageHeader } from "../../../components/page-header/page-header";
import { useNavigate } from "react-router-dom";
import { TabComponent } from "../../../components/tab-component/tab-component";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

interface ItemTabProps {
    label: string;
    key: string;
    FormComponent: any;
    disabled?: boolean;
    loading?: boolean;
    title?: string;
    hiddenCard?: boolean;
}

interface PageFormContainerProps {
    items: ItemTabProps[];
    title: string;
    onBack?: () => void;
    activeKey: string;
    setActiveKey: (key: string) => void;
}

export const PageFormTabContainer = ({
                                         items,
                                         title,
                                         onBack,
                                         activeKey,
                                         setActiveKey,
                                     }: PageFormContainerProps) => {
    const navigate = useNavigate();
    const {width} = useWindowDimensions();
    const itemsTab = useMemo(() => {
        return items.map((i) => ({
            label: i.label,
            key: i.key,
            disabled: i.disabled,
            children: i?.hiddenCard ? i.FormComponent :(
                <Card loading={i.loading} size={"small"}>
                    {i.FormComponent}
                </Card>
            ),
        }));
    }, [items]);
    return (
        <>
            <PageHeader title={title} onBack={() => {
                if (typeof onBack === 'function') {
                    onBack()
                } else {
                    navigate(-1)
                }
            }}/>
            <Divider/>
            <TabComponent
                tabPosition={width < 500 ? "top" : "left"}
                activeKey={activeKey}
                onChange={(data) => {
                    setActiveKey(data)
                }}
                items={itemsTab}
            />
        </>
    );
};
