import React, { useState } from 'react'
import { Spin, Tooltip } from 'antd'
import { api } from "../../services/api";
import { ConfigOptionTagAction } from "../config-option-tag-action/config-option-tag-action";

export const TaskTagAction = ({ task, val, updateDataList, fieldName, labelName, style = {} }) => {
    const [loading, setLoading] = useState(false)
    const onChangeConfigOption = (configOption: any, field: 'priority_id' | 'status_id') => {
        setLoading(true)
        api
            .put(`/tasks/${task.id}`, {
                ...task,
                [field]: configOption.id,
            })
            .then(({ data }) => {
                updateDataList(data.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }
    return (
        <Spin spinning={loading}>
            <Tooltip title={labelName}>
                <ConfigOptionTagAction
                    style={style}
                    TagDisplay={({item}) => item?.name}
                    onChange={(configOption: any) => onChangeConfigOption(configOption, fieldName)}
                    configOption={val}
                />
            </Tooltip>
        </Spin>
    )
}
