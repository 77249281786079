import React, { useEffect, useState } from "react";
import { useGetAllApi } from "rk-react-component";
import { PageIndexContainer } from "../../../containers/pages/page-index-container/page-index-container";
import { api } from "../../../services/api";
import { useNavigate } from "react-router-dom";
import { TableTask } from "../../../containers/tables/table-task/table-task";
import { Avatar, Divider, Empty, Flex, Input, Modal, Space, Tooltip, Typography } from "antd";
import { ModelEnum } from "../../../@types/model.enum";
import moment from "moment/moment";
import { PlayTimerAction } from "../../../components/play-timer/play-timer";
import { TaskTagAction } from "../../../components/task-tag-action/task-tag-action";
import { FormFilterTask } from "../../../containers/form-filter/form-filter-task/form-filter-task";
import { SelectAssigneesAction } from "../../../containers/inputs/select-assignees-action/select-assignees-action";

export const TaskIndexPage = () => {
    const navigate = useNavigate();
    const [taskSelected, setTaskSelected] = useState(null);
    const { dataList, loading, fetchDataList, pagination, filter, remove, updateDataList } = useGetAllApi<
        any,
        any
    >({
        url: "/tasks",
        api,
        initialRequest: false,
        filterKey: "taskIndexPage"
    });
    useEffect(() => {
        fetchDataList({ per_page: 10, page: 1, ...filter });
    }, []);
    return (
        <>
            <PageIndexContainer
                title={"Atividades"}
                btnTitle={"Novo Atividade"}
                url={"/tasks"}
                FormFilterComponent={
                    <FormFilterTask onFinish={fetchDataList} initialValues={filter} />
                }
                TableComponent={() => (
                    <TableTask
                        updateDataList={updateDataList}
                        onView={(record) => setTaskSelected(record)}
                        onDelete={remove}
                        filter={filter}
                        dataSource={dataList}
                        loading={loading}
                        loadData={(params) => fetchDataList({ ...filter, ...params })}
                        pagination={pagination}
                        onEdit={(record) => navigate("/tasks/" + record?.id)}
                    />
                )}
            />
            <Modal
                destroyOnClose={true}
                footer={false}
                open={taskSelected}
                onCancel={() => setTaskSelected(null)}
                width={"90%"}
            >
                <Space direction={"vertical"} style={{ width: "100%" }}>
                    <Typography.Title>{taskSelected?.title}</Typography.Title>
                    <Space direction={"horizontal"} size={40}>
                        <Flex vertical={true} >
                            <Typography.Text type={"secondary"}>Status</Typography.Text>
                            <Flex style={{height: 32}} align={'center'}>

                            <TaskTagAction
                                task={taskSelected}
                                val={taskSelected?.status}
                                updateDataList={(record) => {
                                    setTaskSelected(record);
                                    updateDataList(record);
                                }}
                                fieldName={"status_id"}
                                labelName={"name"}
                            />
                            </Flex>
                        </Flex>
                        <Flex vertical={true}>
                        <Typography.Text type={"secondary"}>Prioridade</Typography.Text>
                            <Flex style={{height: 32}}  align={'center'}>
                        <TaskTagAction
                            task={taskSelected}
                            val={taskSelected?.priority}
                            updateDataList={(record) => {
                                setTaskSelected(record);
                                updateDataList(record);
                            }}
                            fieldName={"priority_id"}
                            labelName={"name"}
                        />
                            </Flex>
                        </Flex>
                        <Flex vertical={true}>
                        <Typography.Text type={"secondary"}>Tempo</Typography.Text>
                            <Flex style={{height: 32}} align={'flex-end'}>
                        <PlayTimerAction
                            api={api}
                            justify={'flex-start'}
                            url={"task-timers"}
                            modelId={taskSelected?.id} timer={taskSelected?.openTimer}
                            totalTimer={taskSelected?.totalTimer}
                            onPlay={() => {
                                api.post("/timers", {
                                    model_type: ModelEnum.TASK,
                                    model_id: taskSelected?.id,
                                    start_at: moment().toISOString()
                                }).then(({ data }) => {
                                    setTaskSelected((old) => ({
                                        ...old,
                                        openTimer: data.data
                                    }));
                                    fetchDataList(filter);
                                });
                            }}
                            onPause={() => {
                                api.put("/timers/" + taskSelected?.openTimer?.id, {
                                    end_at: moment().toISOString()
                                }).then(() => {
                                    fetchDataList(filter, (data) => {
                                        setTaskSelected((old) => data.find((item) => item.id === old.id));
                                    });
                                });
                            }}
                        />
                            </Flex>
                        </Flex>
                        <Flex vertical={true}>
                        <Typography.Text type={"secondary"}>Atribuído para</Typography.Text>
                        <SelectAssigneesAction
                            style={{}}
                            model={taskSelected}
                            values={taskSelected?.users_assignees || []}
                            onChange={() => {
                            }}
                            updateDataList={(record) => {
                                setTaskSelected(record);
                                updateDataList(record);
                            }}
                        />
                        </Flex>
                    </Space>
                    <Input.TextArea value={taskSelected?.description} rows={10} />
                    <Divider orientation={"left"}>Anexos </Divider>
                    <Empty />
                </Space>
            </Modal>
        </>
    );
};
