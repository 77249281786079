import React, { useEffect } from "react";
import { useGetAllApi } from "rk-react-component";
import { TableUser } from "../../../containers/tables/table-user/table-user";
import { PageIndexContainer } from "../../../containers/pages/page-index-container/page-index-container";
import { FormFilterUser } from "../../../containers/form-filter/form-filter-user/form-filter-user";
import { api } from "../../../services/api";
import { useNavigate } from "react-router-dom";

export const UserIndexPage = () => {
    const navigate = useNavigate();
    const { dataList, loading, fetchDataList, pagination, filter, remove } = useGetAllApi<
        any,
        any
    >({
        url: "/users",
        api,
        initialRequest: false,
        filterKey: "userIndexPage"
    });
    useEffect(() => {
        fetchDataList({ per_page: 10, page: 1, ...filter });
    }, []);
    return (
        <PageIndexContainer
            title={"Usuários"}
            btnTitle={"Novo Usuário"}
            url={"/users"}
            FormFilterComponent={
                <FormFilterUser onFinish={fetchDataList} initialValues={filter} />
            }
            TableComponent={() => (
                <TableUser
                    onDelete={remove}
                    filter={filter}
                    dataSource={dataList}
                    loading={loading}
                    loadData={fetchDataList}
                    pagination={pagination}
                    onEdit={(record) => navigate("/users/" + record?.id)}
                />
            )}
        />
    );
};
