import React, { useContext, useState } from "react";
import { SignInPage } from "./signin-page";
import { MeContext } from "../../contexts/me-context/me-context";
import { api } from "../../services/api";


export const SignInFactory = () => {
  const { setMe } = useContext(MeContext);
  const [isLoading, setIsLoading] = useState(false);

  const signInWithEmail = ({ email = "", password = "" }) => {
    setIsLoading(true);
    api
      .post("/sign-in", { email: email.trim(), password: password.trim() })
      .then(({ data: dataUser }) => {
        console.log(dataUser)
        localStorage.setItem("user", JSON.stringify(dataUser.data));
        setMe(dataUser.data);
      })
      .catch((e) => {
        console.warn(e);
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };



  return (
    <SignInPage
      loading={isLoading}
      onFinish={signInWithEmail}
    />
  );
};
