import { SelectAction } from "rk-react-component";
import { api } from "../../../services/api";
import React from "react";
import { UserSelectRender } from "../../../components/user-select-render/user-select-render";

export const SelectUser = ({...props}) => {
    return (
        <SelectAction
            api={api}
            url={"/users"}
            placeholder="Selecione"
            displayLabel={({ item }) => <UserSelectRender user={item}/>}
            displayLabelValue={({ item }) => <UserSelectRender user={item} hiddenEmail={true} size={24}/>}
            {...props}
        />
    )
}