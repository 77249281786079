import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {ProFormText} from "@ant-design/pro-components";
import {UserOutlined} from "@ant-design/icons";
import {Button, Card, Col, Divider, Form, Image, Modal, Row, Typography} from "antd";
import {api} from "../../services/api";

export const ForgotPassword = () => {
    const navigate = useNavigate()
    const [modal, setModal] = useState(false)
    const [form] = Form.useForm()


    return (
        <div
            style={{
                backgroundColor: "white",
                height: "calc(100vh)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
            }}
        >
            <div style={{display: "flex", flex: 1}}/>
            <div style={{display: "flex", flex: 1}}>
                <Row style={{width: '100%'}} align={"middle"} justify={"center"}>
                    <Col xs={20} sm={16} md={10} lg={6}>
                        <Card style={{width: '100%'}}>
                            <Form form={form} onFinish={(dataForm) => {
                                api.post('/forgot-password', dataForm).then(() => {
                                    setModal(true)
                                })
                            }}>

                                <Row justify={'center'}>
                                    <Image src={require('../../assets/logo-color-vertical.png')} preview={false}
                                           style={{maxWidth: 240}}/>
                                    <Divider/>
                                    <Typography.Title level={5} style={{
                                        color: 'gray',
                                        marginTop: 8,
                                        marginBottom: 24,
                                        textAlign: 'center'
                                    }}>
                                        Você receberá um código através do seu e-mail de cadastro para resetar sua
                                        senha.
                                    </Typography.Title>
                                </Row>
                                <ProFormText
                                    name="email"
                                    fieldProps={{
                                        size: 'large',
                                        prefix: <UserOutlined className={'prefixIcon'}/>,
                                    }}
                                    placeholder={'Email'}
                                />
                                <Button
                                    type={"primary"}
                                    block
                                    htmlType={'submit'}
                                >
                                    Enviar
                                </Button>
                                <Button
                                    style={{marginTop: 12}}
                                    type={"link"}
                                    block
                                    onClick={() => {
                                        navigate('/signin')
                                    }}
                                >
                                    Login
                                </Button>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </div>
            <div style={{display: "flex", flex: 2}}/>
            <Modal open={modal} onCancel={() => setModal(false)} closable={false} footer={null}>
                <Typography.Title level={4}>
                    Esqueceu a senha?
                </Typography.Title>
                <Typography.Title level={5} style={{color: 'gray'}}>
                    Um email com as instruções para redefinir sua senha foi enviado para o seu endereço de
                    email. Por favor, verifique sua caixa de entrada e siga as instruções fornecidas.
                </Typography.Title>
                <Button
                    block
                    type={"link"}
                    style={{marginTop: 24}}
                    onClick={() => setModal(false)}
                >
                    Fechar
                </Button>
            </Modal>
        </div>
    );
}
