import { Image } from "antd";
import React from "react";

export const MenuHeaderRender = ({ configuration, logo }) => {
    const collapsed = configuration?.collapsed === undefined ? true : configuration?.collapsed;
    if (configuration?.collapsed === undefined && configuration?.isMobile === undefined) {
        return <LogoMobile />;
    }
    if (collapsed && !configuration?.isMobile) {
        return <LogoCollapsed/>;
    }
    return collapsed ? logo : (<OpenSideBar configuration={configuration}/>);
};

const LogoMobile = () => {
    return (
        <Image
            style={{ marginBottom: 24 }}
            height={30}
            src={require("../assets/logo-white-horizontal.png")} // Mobile
            preview={false}
        />
    );
};

const LogoCollapsed = () => {
    return <Image
        style={{ height: 40, width: 40 }}
        src={require("../assets/icon-bordered.png")} // Collapsed
        preview={false}
    />
}

const OpenSideBar = ({configuration}) => {
    return (
        <div
            style={{
                width: "100%",
                justifyContent: "start",
                alignItems: "start",
                display: "flex",
                marginTop: configuration?.isMobile ? 8 : -8,
                marginLeft: configuration?.isMobile ? 12 : 0,
                marginBottom: configuration?.isMobile ? 12 : -8
            }}
        >
            <Image
                style={{ maxHeight: 36 }}
                src={require("../assets/logo-white-horizontal.png")} // Open sidebar
                preview={false}
            />
        </div>
    )
}