import { SelectAction } from "rk-react-component";
import { api } from "../../../services/api";
import React from "react";
import { ConfigOptionTypeEnum } from "../../../@types/config-option-type.enum";

interface SelectConfigOptionProps {
    style?: any;
    placeholder?: string
    type: ConfigOptionTypeEnum;
    allowClear?: boolean;
}

export const SelectConfigOption = ({type, ...props}: SelectConfigOptionProps) => {
    return (
        <SelectAction
            api={api}
            url={"/config-options"}
            fixParams={{type}}
            placeholder="Selecione"
            {...props}
        />
    )
}