import React from "react";
import {
  FormFilterContainer,
  FormFilterItem,
  InputPhone,
} from "rk-react-component";
import { Form, Input } from "antd";

interface FormFilterProjectProps {
  onFinish: (data: any) => void;
  initialValues: any;
}

export const FormFilterProject = ({
  onFinish,
  initialValues,
}: FormFilterProjectProps) => {
  const [form] = Form.useForm();
  return (
    <FormFilterContainer
      formProps={{
        initialValues: initialValues,
        onFinish: (data) => {
          onFinish({ ...initialValues, ...data});
        },
      }}
      form={form}
      onReset={() => onFinish({})}
      hiddenShowMore={true}
      showTrashOption={true}
    >
      {() => (
        <>
          <FormFilterItem name={"name_lk"}>
            <Input placeholder={"Nome"} data-testid={"name_lk"} />
          </FormFilterItem>
        </>
      )}
    </FormFilterContainer>
  );
};
