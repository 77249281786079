import React from "react";
import { FormFilterContainer, FormFilterItem, SelectAction } from "rk-react-component";
import { Form, Input, Switch } from "antd";
import { SelectConfigOption } from "../../inputs/select-config-option/select-config-option";
import { ConfigOptionTypeEnum } from "../../../@types/config-option-type.enum";
import { api } from "../../../services/api";

interface FormFilterTaskProps {
  onFinish: (data: any) => void;
  initialValues: any;
}

export const FormFilterTask = ({
  onFinish,
  initialValues,
}: FormFilterTaskProps) => {
  const [form] = Form.useForm();
  console.log(initialValues);
  return (
    <FormFilterContainer
      formProps={{
        initialValues: initialValues,
        onFinish: (data) => {
          onFinish({ ...initialValues, ...data});
        },
      }}
      form={form}
      onReset={() => onFinish({})}
      hiddenShowMore={true}
      showTrashOption={true}
    >
      {() => (
        <>
          <FormFilterItem name={"title_lk"}>
            <Input placeholder={"Título"} data-testid={"title_lk"} />
          </FormFilterItem>
            <FormFilterItem name={"status_id"}>
                <SelectConfigOption
                    type={ConfigOptionTypeEnum.STATUS_ATIVIDADES}
                    style={{width: 200}}
                    placeholder={"Status"}
                    allowClear={true}
                />
            </FormFilterItem>
            <FormFilterItem name={"project_id"}>
                <SelectAction
                    style={{width: 200}}
                    placeholder={"Projeto"}
                    allowClear={true}
                    api={api}
                    url={"/projects"}
                />
            </FormFilterItem>
            <FormFilterItem name={"priority_id"}>
                <SelectConfigOption
                    type={ConfigOptionTypeEnum.PRIORIDADE_ATIVIDADES}
                    style={{width: 200}}
                    placeholder={"Prioridade"}
                    allowClear={true}
                />
            </FormFilterItem>
            <FormFilterItem name={"hidden_finished"}>
                <Switch checkedChildren="Ocultar Fechadas" unCheckedChildren="Exibir Fechadas"/>
            </FormFilterItem>
        </>
      )}
    </FormFilterContainer>
  );
};
