import { Flex, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { FaPause, FaPlay } from "react-icons/fa";
import { AxiosInstance } from "axios";
import { formatSecondToTimer } from "../../services/format-data";
import moment, { Moment } from "moment";
import { object } from "prop-types";

interface PlayTimerActionProps {
    timer?: any;
    totalTimer?: number;
    modelId: number;
    url: string;
    api: AxiosInstance;
    onPlay?: any;
    onPause?: any
    align?: string
    justify?: string
}

export const PlayTimerAction = ({ timer, totalTimer = 0, onPlay, onPause , align = 'center', justify = 'center'}: PlayTimerActionProps) => {
    const [countTimer, setCountTimer] = useState<number>(0);
    const [intervalInstance, setIntervalInstance] = useState(null);
    useEffect(() => {
            console.log(timer)
        if (timer?.start_at && !timer?.end_at) {
            const objTimer = setInterval(() => {
                const startAt = moment(timer.start_at);
                const now = moment();
                const diff = now.diff(startAt, "seconds");
                setCountTimer(diff);
            }, 1000);
            setIntervalInstance(objTimer);
        } else {
            clearInterval(intervalInstance)
            setCountTimer(0);
        }
    }, [timer]);

    return (
        <Flex align={align} justify={justify} style={{minWidth: 120}}>
            <Typography.Text>{formatSecondToTimer(totalTimer + countTimer)}</Typography.Text>

            {!timer?.start_at ? (
                <Flex style={{padding: 8, cursor: "pointer"}} align={"center"} justify={"center"}>
                <FaPlay
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        onPlay?.()
                        setCountTimer(0);
                    }}
                />
                </Flex>
            ) : (
                <Flex
                    style={{padding: 8, cursor: "pointer"}} align={align} justify={justify}
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        onPause?.()
                        setCountTimer(0);
                    }}
                >
                    <FaPause color={"red"} />
                </Flex>
            )}
        </Flex>
    );
};