import { Avatar, Typography } from "antd";
import React from "react";
import { UserModel } from "../../../@types/user";
import { TableContainer } from "rk-react-component";

interface TableProjectProps {
  dataSource: UserModel[];
  loading: boolean;
  pagination: any;
  loadData: (params: any) => void;
  onEdit?: (params: any) => void;
  filter?: any;
    onDelete?: any
}

export const TableProject = ({
  dataSource,
  loading,
  loadData,
  pagination,
  onEdit,
  filter,
                              onDelete
}: TableProjectProps) => {
  return (
    <TableContainer
      filter={filter}
      onEdit={onEdit}
      onDelete={onDelete}
      pagination={pagination}
      loadData={loadData}
      id={"table_user"}
      loading={loading}
      columns={[
        {
          key: "name",
          dataIndex: "name",
          title: "Nome",
          sorter: true,
          render: (val) => <Typography.Text strong>{val}</Typography.Text>,
        },
      ]}
      dataSource={dataSource}
    />
  );
};
