import { Link, Route, Routes } from "react-router-dom";
import { SignInFactory } from "../pages/signin/signin-factory";
import { ForgotPassword } from "../pages/forgot-password/forgot-password";
import { ResetPassword } from "../pages/reset-password/reset-password";
import React from "react";

export const AuthRoutes = () => {
    return (
        <Routes>
            <Route path={"/signin"} element={<SignInFactory />} />
            <Route
                path={"/signup"}
                element={<Link to={"/signin"}>signup</Link>}
            />
            <Route path={"/forgot-password"} element={<ForgotPassword />} />
            <Route
                path={"/reset-password"}
                element={<ResetPassword reload={false} />}
            />
        </Routes>
    )
}