import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { MainLayout } from "./main-layout";
import { MeContext } from "../contexts/me-context/me-context";
import {notification as notificationAntd} from 'antd';
import { FilterContext, RkContext } from "rk-react-component";
import "moment/locale/pt-br";
import { messaging, getToken, onMessage } from '../services/firebase.js';

function App() {
    const [me, setMe] = useState(null);
    const [role, setRole] = useState(null);
    const [collapsed, setCollapsed] = useState(null);
    const [filter, setFilter] = useState({
        masterIndex: {},
    });
    useEffect(() => {
        const getNotificationPermissionAndToken = async () => {
            try {
                const permission = await Notification.requestPermission();
                if (permission === 'granted') {
                    const token = await getToken(messaging, {vapidKey: 'BI5Am82g1RRPp20AE1BYVW_3z_uxafy7U7QThET-B1GKbNpYsmm8dzccPZNn3jtD1f3QZp1HimGT0H1T6ak1Ng8'});
                    console.log('Token:', token);
                } else {
                    console.error('Permission not granted for Notification');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        getNotificationPermissionAndToken();

        onMessage(messaging, (payload) => {
            console.log('Mensagem recebida em foreground: ', payload);
            // Exiba a notificação ou trate a mensagem de outra maneira
            notificationAntd.info({
                message: payload.notification.title,
                description: payload.notification.body,
            })
            // alert(`Notificação: ${payload.notification.title} - ${payload.notification.body}`);
        });
    }, []);
    useEffect(() => {
        const userAux = localStorage.getItem("user");
        const activeRoleAux = localStorage.getItem("active_role");
        const filterAux = localStorage.getItem("filter");
        const collapsedAux = localStorage.getItem("collapsed");
        setFilter(filterAux ? JSON.parse(filterAux) : JSON.stringify({}));
        setMe(userAux ? JSON.parse(userAux) : JSON.stringify({ id: null }));
        setRole(
            activeRoleAux
                ? JSON.parse(activeRoleAux)
                : JSON.stringify({ id: null }),
        );
        setCollapsed(collapsedAux === 'true');
    }, []);
    useEffect(() => {
        localStorage.setItem("filter", JSON.stringify(filter));
    },[filter])
    useEffect(() => {
        console.log(collapsed)
        localStorage.setItem("collapsed", collapsed);
    },[collapsed])
    return (
        <RkContext.Provider
            value={{ theme: { colorHover: "rgba(107, 107, 107, 0.1)" } }}
        >
            <MeContext.Provider value={{ me, setMe, role, setRole, collapsed, setCollapsed }}>
                <FilterContext.Provider value={{ filter, setFilter }}>
                    <BrowserRouter>
                        <MainLayout me={me} role={role} />
                    </BrowserRouter>
                </FilterContext.Provider>
            </MeContext.Provider>
        </RkContext.Provider>
    );
}

export default App;
