import { Dropdown, Space, Spin, Tag } from 'antd'
import React, { ReactNode, useMemo } from "react";
import { FaCaretDown } from 'react-icons/fa'
import { useGetAllApi } from 'rk-react-component'
import { api } from "../../services/api";

interface ConfigOptionTagActionProps {
    configOption: any
    TagDisplay: any
    onChange: (configOption: any) => void
    type?: string
    style?: any
}

export const ConfigOptionTagAction = ({ configOption, onChange, type, TagDisplay, style }: ConfigOptionTagActionProps) => {
    const { dataList, fetchDataList, loading } = useGetAllApi<any, any>({
        url: '/config-options',
        api: api,
        fixParams: { type: type || configOption?.type },
        initialRequest: false,
    })

    const items = useMemo(() => {
                return dataList.map((i) => ({
                    key: i.id,
                    label: (
                        <a
                            onClick={(event) => {
                                event.stopPropagation()
                                event.preventDefault()
                                onChange(i)
                            }}
                        >
                            <TagDisplay item={i} />
                        </a>
                    ),
                }))
    }, [dataList])
    if (!configOption?.id) {
        return '-'
    }
    return (
        <Dropdown
            trigger={['click']}
            overlayStyle={{ minHeight: 80, ...style }}
            dropdownRender={(menu) => (
                <Spin style={{ minHeight: 80, backgroundColor: 'white' }} spinning={loading}>
                    {menu}
                </Spin>
            )}
            menu={{
                items,
                onClick: (e) => {
                    e.domEvent.stopPropagation()
                },
            }}
            onOpenChange={(open) => {
                if (open) {
                    fetchDataList({})
                }
            }}
        >
            <Tag
                color={configOption.back_color}
                style={{ cursor: 'pointer' }}
                onClick={(event) => {
                    event.stopPropagation()
                    event.preventDefault()
                }}
            >
                <Space size={12}>
                    {configOption.name}
                    <FaCaretDown />
                </Space>
            </Tag>
        </Dropdown>
    )
}
