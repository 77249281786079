import React from "react";
import { Col, Form, Input, Row } from "antd";
import {
    FormContainer,
    FormItem,
    useFormApi
} from "rk-react-component";
import { api } from "../../../services/api";
import { SelectProject } from "../../inputs/select-project/select-project";
import { SelectConfigOption } from "../../inputs/select-config-option/select-config-option";
import { ConfigOptionTypeEnum } from "../../../@types/config-option-type.enum";
import { SelectAssignees } from "../../inputs/select-assignees/select-assignees";

interface FormTaskProps {
    onFinish: (data: any) => void;
    initialValues?: any;
    onBack?: () => void;
}

export const FormTask = ({ onFinish, initialValues, onBack }: FormTaskProps) => {
    const [form] = Form.useForm();
    const { save, loading } = useFormApi<any, any>({ url: "/tasks", id: initialValues?.id, api: api });
    return (
        <FormContainer
            name={"form_task"}
            initialValues={{
                ...initialValues,
                users_assignees: initialValues?.users_assignees?.map((item) => item.id),
        }}
            form={form}
            onFinish={(data) => save(data, onFinish, console.warn)}
            loading={loading}
            onBack={onBack}
        >
            <Row gutter={12}>
                <Col span={16}>
                    <FormItem
                        label={"Título"}
                        name={"title"}
                        rules={[{ required: true, message: "O Título é obrigatório!" }]}
                    >
                        <Input placeholder={"Título"} data-testid={"title"} />
                    </FormItem>
                </Col>
                <Col span={8}>
                    <FormItem
                        label={"Projeto"}
                        name={"project_id"}
                    >
                        <SelectProject />
                    </FormItem>
                </Col>
                <Col span={12}>
                    <FormItem
                        label={"Atribuído a"}
                        name={"users_assignees"}
                    >
                        <SelectAssignees  defaultOptions={initialValues?.users_assignees || []}/>
                    </FormItem>
                </Col>
                <Col span={6}>
                    <FormItem
                        label={"Status"}
                        name={"status_id"}
                    >
                        <SelectConfigOption type={ConfigOptionTypeEnum.STATUS_ATIVIDADES} />
                    </FormItem>
                </Col>
                <Col span={6}>
                    <FormItem
                        label={"Prioridade"}
                        name={"priority_id"}
                    >
                        <SelectConfigOption type={ConfigOptionTypeEnum.PRIORIDADE_ATIVIDADES} />
                    </FormItem>
                </Col>
                <Col span={24}>
                    <FormItem
                        label={"Descrição"}
                        name={"description"}
                    >
                        <Input.TextArea placeholder={"Descrição"} data-testid={"description"} />
                    </FormItem>
                </Col>
            </Row>
        </FormContainer>
    );
};
