import { Avatar, Typography } from "antd";
import React from "react";
import { UserModel } from "../../../@types/user";
import { formatBrazilianPhoneNumber, TableContainer } from "rk-react-component";
import { IMG_DEFAULT } from "../../../services/constants";

interface TableUserProps {
  dataSource: UserModel[];
  loading: boolean;
  pagination: any;
  loadData: (params: any) => void;
  onEdit?: (params: any) => void;
  filter?: any;
    onDelete?: any
}

export const TableUser = ({
  dataSource,
  loading,
  loadData,
  pagination,
  onEdit,
  filter,
                              onDelete
}: TableUserProps) => {
  return (
    <TableContainer
      filter={filter}
      onEdit={onEdit}
      onDelete={onDelete}
      pagination={pagination}
      loadData={loadData}
      id={"table_user"}
      loading={loading}
      columns={[
        {
          dataIndex: "avatar",
          key: "avatar",
          title: "Foto",
          render: (val) => (
              <Avatar
                  src={val || IMG_DEFAULT}
              />
          ),
        },
        {
          key: "name",
          dataIndex: "name",
          title: "Nome",
          sorter: true,
          render: (val) => <Typography.Text strong>{val}</Typography.Text>,
        },
        {
          key: "email",
          dataIndex: "email",
          title: "Email",
          sorter: true,
          render: (val) => <Typography.Text strong>{val}</Typography.Text>,
        },
      ]}
      dataSource={dataSource}
    />
  );
};
