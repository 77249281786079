import { SelectAction } from "rk-react-component";
import { api } from "../../../services/api";
import React from "react";

export const SelectProject = ({...props}) => {
    return (
        <SelectAction
            api={api}
            url={"/projects"}
            placeholder="Selecione um Projeto"
            {...props}
        />
    )
}