// Import the functions you need from the SDKs you need
import * as firebase from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyBdR62Xas-YKEHjoTtcIr8MzFE2mwaguUo",
    authDomain: "rockapps-erp.firebaseapp.com",
    projectId: "rockapps-erp",
    storageBucket: "rockapps-erp.appspot.com",
    messagingSenderId: "474491170028",
    appId: "1:474491170028:web:0fa44a977c8acff17389c9"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

const messaging = getMessaging(app)

export { messaging, getToken, onMessage };
