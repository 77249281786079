import React from 'react'
import { FormContainer, FormItem, useFormApi } from 'rk-react-component'
import { Form, Input } from 'antd'
import { api } from '../../../services/api'
import { InputPasswordStrength } from '../../inputs/input-password-strength/input-password-strength'

interface FormPasswordProps {
  onFinish: (data: any) => void
  userId: number
}

export const FormPassword = ({ onFinish, userId }: FormPasswordProps) => {
  const [form] = Form.useForm()
  const { save, loading } = useFormApi<any, any>({
    url: `/change-password`,
    id: null,
    fixParams: { user_id: userId },
    api,
  })
  return (
    <FormContainer
      name={'form_password'}
      initialValues={null}
      form={form}
      onFinish={(data) => {
        save(
          {
            ...data,
          },
          (response) => {
            onFinish(response)
          },
          (e) => console.warn(e),
        )
      }}
      loading={loading}
    >
      <InputPasswordStrength formItemProps={{ label: 'Senha Nova', name: 'password_new' }} />
      <FormItem label={'Confirmar Senha'} name={'password_confirmation'}>
        <Input.Password />
      </FormItem>
    </FormContainer>
  )
}
