import React, { useContext } from "react";
import { Form, Input, Upload } from "antd";
import {
    FormContainer,
    FormItem,
    InputCpf,
    InputPhone,
    useFormApi
} from "rk-react-component";
import { UserModel } from "../../../@types/user";
import { api } from "../../../services/api";
import { MeContext } from "../../../contexts/me-context/me-context";

interface FormUserProps {
    onFinish: (data: UserModel) => void;
    initialValues?: UserModel;
    onBack?: () => void;
}

export const FormUser = ({
                             onFinish,
                             initialValues,
                             onBack
                         }: FormUserProps) => {
    const [form] = Form.useForm();
    const {setMe, me} = useContext(MeContext)
    const photo_upload = Form.useWatch("photo_upload", form);
    const { save, loading } = useFormApi<any, any>({
        url: "/users",
        id: initialValues?.id,
        api: api,
        withFile: true
    });
    return (
        <FormContainer
            name={"form_user"}
            initialValues={{
                ...initialValues,
                photo_upload: {
                    fileList: initialValues?.avatar
                        ? [
                            {
                                uid: initialValues?.id,
                                name: "avatar",
                                status: "done",
                                url: initialValues?.avatar
                            }
                        ]
                        : undefined
                }
            }}
            form={form}
            onFinish={(data) => {
                const formData = new FormData();
                if (data?.photo_upload?.fileList?.[0]?.originFileObj) {
                    formData.append(
                        "avatar",
                        data?.photo_upload?.fileList?.[0]?.originFileObj
                    );
                }
                formData.append("name", data?.name);
                formData.append("email", data?.email);
                save(
                    formData,
                    (response) => {
                        onFinish(response);
                        if(response?.id === me?.id) {
                            console.log(me?.access_token)
                            const userUpdated = { ...response, access_token: me?.access_token };
                            setMe(userUpdated);
                            localStorage.setItem("user", JSON.stringify(userUpdated));
                        }

                    },
                    console.warn
                );
            }}
            loading={loading}
            onBack={onBack}
        >
            <FormItem label={"Foto"} name={"photo_upload"}>
                <Upload
                    fileList={photo_upload?.fileList}
                    listType="picture-card"
                    beforeUpload={() => false}
                >
                    {!photo_upload?.fileList?.length && "Upload"}
                </Upload>
            </FormItem>
            <FormItem
                label={"Nome"}
                name={"name"}
                rules={[{ required: true, message: "O Nome é obrigatório!" }]}
            >
                <Input placeholder={"Nome"} data-testid={"name"} />
            </FormItem>
            <FormItem
                label={"Email"}
                name={"email"}
                rules={[
                    { required: true, message: "O Email é obrigatório!" },
                    { type: "email", message: "Email inválido!" }
                ]}
            >
                <Input placeholder={"Email"} data-testid={"email"} disabled={!!initialValues?.id} />
            </FormItem>
        </FormContainer>
    );
};
