import React from "react";
import { Space } from "antd";
import debug from "debug";

const testLogger = debug("something");
testLogger.enabled = true;


export const DashboardPage: React.FC = () => {
    return (
        <Space direction={"vertical"} size={12} style={{ width: "100%" }}>
        </Space>
    );
};
