import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GetOneApiReturn, useGetOneApi } from "rk-react-component";
import { UserModel } from "../../../@types/user";
import { FormUser } from "../../../containers/forms/form-user/form-user";
import { PageFormTabContainer } from "../../../containers/pages/page-form-tab-container/page-form-tab-container";
import { api } from "../../../services/api";
import { FormPassword } from "../../../containers/forms/form-password/form-password";

export const UserFormPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const {
        data: dataUser,
        loading: loadingUserGetOne,
    }: GetOneApiReturn<UserModel> = useGetOneApi<any>({
        url: "/users",
        id,
        api,
    });
    const [activeKey, setActiveKey] = React.useState("tab_user");
    return (
        <PageFormTabContainer
            activeKey={activeKey}
            setActiveKey={setActiveKey}
            title={"Usuário"}
            items={[
                {
                    disabled: false,
                    key: "tab_user",
                    label: "Info Geral",
                    loading: loadingUserGetOne,
                    title: "Novo Usuário",
                    FormComponent: (
                        <FormUser
                            initialValues={dataUser}
                            onFinish={(dataApi) => {
                                navigate(`/users/${dataApi?.id}`);
                            }}
                        />
                    ),
                },
                {
                    disabled: !dataUser?.id,
                    key: "tab_password",
                    label: "Senha",
                    loading: false,
                    title: "Trocar Senha",
                    FormComponent: (<FormPassword userId={dataUser?.id} onFinish={() => {}}/>) ,
                },
            ]}
        />
    );
};
