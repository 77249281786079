import { Route } from "react-router-dom";
import { DashboardPage } from "../pages/dashboard/dashboard-page";
import React from "react";
import {
    DashboardIcon, ProjectIcon,
    ReportIcon, TaskIcon,
    UserIcon
} from "./icons-module";
import { ResetPassword } from "../pages/reset-password/reset-password";
import { UserFormPage } from "../pages/user/user-form-page/user-form-page";
import { UserIndexPage } from "../pages/user/user-index-page/user-index-page";
import { ProjectIndexPage } from "../pages/project/project-index-page/project-index-page";
import { ProjectFormPage } from "../pages/project/project-form-page/project-form-page";
import { TaskFormPage } from "../pages/task/task-form-page/task-form-page";
import { TaskIndexPage } from "../pages/task/task-index-page/task-index-page";

export const dashboard = {
    location: { path: "/", name: "Dashboard", icon: <DashboardIcon /> },
    routes: <Route path={""} element={<DashboardPage />} />
};
export const auth = {
    location: {},
    routes: (
        <>
            <Route
                path={"/reset-password"}
                element={<ResetPassword reload={true} />}
            />
        </>
    )
};
export const report = {
    location: { path: "/reports", name: "Relatórios", icon: <ReportIcon /> },
    routes: null
};
export const user = {
    location: { path: "/users", name: "Usuários", icon: <UserIcon /> },
    routes: (
        <>
            <Route path={"/users/store"} element={<UserFormPage />} />
            <Route path={"/users/:id"} element={<UserFormPage />} />
            <Route path={"/users"} element={<UserIndexPage />} />
        </>
    )
};
export const project = {
    location: { path: "/projects", name: "Projetos", icon: <ProjectIcon /> },
    routes: (
        <>
            <Route path={"/projects/store"} element={<ProjectFormPage />} />
            <Route path={"/projects/:id"} element={<ProjectFormPage />} />
            <Route path={"/projects"} element={<ProjectIndexPage />} />
        </>
    )
};
export const task = {
    location: { path: "/tasks", name: "Atividades", icon: <TaskIcon /> },
    routes: (
        <>
            <Route path={"/tasks/store"} element={<TaskFormPage />} />
            <Route path={"/tasks/:id"} element={<TaskFormPage />} />
            <Route path={"/tasks"} element={<TaskIndexPage />} />
        </>
    )
};

export default {
    auth,
    dashboard,
    report,
    user,
};
