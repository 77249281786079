import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetOneApi } from "rk-react-component";
import { FormProject } from "../../../containers/forms/form-project/form-project";
import { PageFormTabContainer } from "../../../containers/pages/page-form-tab-container/page-form-tab-container";
import { api } from "../../../services/api";

export const ProjectFormPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const {
        data: dataProject,
        loading: loadingProjectGetOne,
    } = useGetOneApi<any>({
        url: "/projects",
        id,
        api,
    });
    const [activeKey, setActiveKey] = React.useState(id ? 'tab_task' : "tab_project");

    return (
            <PageFormTabContainer
                activeKey={activeKey}
                setActiveKey={setActiveKey}
                title={dataProject?.id ? dataProject?.name : "Novo Projeto"}
                items={[
                    {
                        disabled: !id,
                        key: "tab_task",
                        label: "Atividades",
                        loading: false,
                        title: "Novo Usuário",
                        FormComponent: null,
                    },
                    {
                        disabled: false,
                        key: "tab_project",
                        label: "Info Geral",
                        loading: loadingProjectGetOne,
                        title: "Novo Usuário",
                        FormComponent: (
                            <FormProject
                                initialValues={dataProject}
                                onFinish={(dataApi) => {
                                    navigate(`/projects/${dataApi?.id}`);
                                }}
                            />
                        ),
                    },
                ]}
            />
    );
};
