import React from "react";
import { Form, Input } from "antd";
import {
    FormContainer,
    FormItem,
    useFormApi
} from "rk-react-component";
import { api } from "../../../services/api";

interface FormProjectProps {
    onFinish: (data: any) => void;
    initialValues?: any;
    onBack?: () => void;
}

export const FormProject = ({
                             onFinish,
                             initialValues,
                             onBack
                         }: FormProjectProps) => {
    const [form] = Form.useForm();
    const { save, loading } = useFormApi<any, any>({
        url: "/projects",
        id: initialValues?.id,
        api: api,
        // withFile: true
    });
    return (
        <FormContainer
            name={"form_project"}
            initialValues={initialValues}
            form={form}
            onFinish={(data) => save(data, onFinish, console.warn)}
            loading={loading}
            onBack={onBack}
        >
            <FormItem
                label={"Nome"}
                name={"name"}
                rules={[{ required: true, message: "O Nome é obrigatório!" }]}
            >
                <Input placeholder={"Nome"} data-testid={"name"} />
            </FormItem>
        </FormContainer>
    );
};
